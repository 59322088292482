import { ApiCall, OptionalParameterDefinition, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', {
	route: endpoints.saveCategory,
	params: {
		id: OptionalParameterDefinition.String,
		title: ParameterDefinition.String,
	},
});

export default saveEndpoint;
