import { useCallback, useRef } from 'react';

const staleTime = 1000 * 60 * 5; // 5 Minutes
export function isStale(date: Date) {
	const passedTime = new Date().valueOf() - date.valueOf();
	return passedTime > staleTime;
}

export function useRefreshingLoad<T>(load: () => Promise<T>) {
	const promise = useRef<Promise<T>>();
	const lastPull = useRef<Date | null>(null);

	const getPromise = useCallback(() => {
		if (!promise.current || lastPull.current === null || isStale(lastPull.current)) {
			lastPull.current = new Date();
			promise.current = load();
		}
		return promise.current;
	}, [load]);

	const markDirty = useCallback(() => {
		lastPull.current = null;
	}, []);

	return {
		getPromise,
		markDirty,
	};
}
