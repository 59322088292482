import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { RouteAsClass } from '@lcms/react-router-helpers';
import { AuthorizedRoute } from '@lcms/react-identity-routes';
import { routes } from './routes';

import IndexPage from '../pages/home';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
import NotFoundPage from '../pages/not-found';
import InvalidDepartment from '../pages/invalid-department';
import SharedPage from '../pages/shared';
import SpotlightPage from '../pages/spotlight';
import NewEmployeePage from '../pages/onboarding';
import AdminPage from '../pages/admin';
import PracticePage from '../pages/practice';
import { guards } from './guards';
import { useReactiveSidebar } from '@lcms/react-sidebar';
import { useToggle } from 'src/features/toggles/use-toggle';

export function Routing() {
	const { set } = useToggle('side-bar');
	useReactiveSidebar(set);

	return (
		<BrowserRouter>
			<RouteAsClass />
			<Switch>
				<Route render={LogoutPage} exact path={routes.logout} />
				<Route render={LoginPage} exact path={routes.login} />
				<AuthorizedRoute render={IndexPage} exact path={routes.index} redirect={null} isAuthorized={guards[routes.index]} />
				<AuthorizedRoute
					render={SharedPage}
					path={routes.shared + '/:department?'}
					redirect={null}
					isAuthorized={guards[routes.shared]}
				/>
				<AuthorizedRoute
					render={SpotlightPage}
					exact
					path={routes.spotlight}
					redirect={null}
					isAuthorized={guards[routes.spotlight]}
				/>
				<AuthorizedRoute
					render={NewEmployeePage}
					exact
					path={routes.onboarding + '/:department?'}
					redirect={null}
					isAuthorized={guards[routes.onboarding]}
				/>
				<AuthorizedRoute render={AdminPage} path={routes.admin.home} redirect={null} isAuthorized={guards[routes.admin.home]} />
				<Route render={InvalidDepartment} exact path={routes.invalidDepartment} />
				<AuthorizedRoute
					render={PracticePage}
					exact
					path={routes.practice}
					redirect={null}
					isAuthorized={guards[routes.admin.home]}
				/>
				<Route render={NotFoundPage} />
			</Switch>
		</BrowserRouter>
	);
}
