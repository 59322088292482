import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', {
	route: endpoints.saveDepartments,
	params: {
		id: OptionalParameterDefinition.String,
		code: OptionalParameterDefinition.String,
		name: ParameterDefinition.String,
		departmentHeadEmail: OptionalParameterDefinition.String,
		departmentHeadName: OptionalParameterDefinition.String,
	},
});

export default saveEndpoint;
