import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { useDepartmentsContext } from './departments-context';

export function useDepartments() {
	const { departments: getDepartments, markDirty } = useDepartmentsContext();
	const [departments, setDepartments] = useAsyncState(getDepartments);

	const refresh = useCallback(() => {
		markDirty();
		return getDepartments().then(setDepartments);
	}, [getDepartments, markDirty, setDepartments]);

	return {
		departments,
		refresh,
	};
}
