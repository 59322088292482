import React, { PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { Toggle, ToggleName, ToggleContext, toggleContext, toggleDefaults } from './toggle-context';

export function ToggleProvider({ children }: PropsWithChildren<{}>) {
	const [toggles, setToggles] = useState<Toggle>(toggleDefaults);

	const setToggle = useCallback((name: ToggleName, value: boolean) => {
		setToggles((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	}, []);

	const toggle = useCallback((name: ToggleName) => {
		setToggles((prev) => {
			return {
				...prev,
				[name]: !prev[name],
			};
		});
	}, []);

	const context: ToggleContext = useMemo(() => {
		return {
			setToggle,
			toggle,
			toggles,
		};
	}, [setToggle, toggle, toggles]);

	return <toggleContext.Provider value={context}>{children}</toggleContext.Provider>;
}
