import { Callback, CallbackFor, Dictionary, array } from '@lcms/helpers';
import { useMount } from '@lcms/react-helpers';
import { useKonami } from '@lcms/react-konami';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import './easter-egg.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function EasterEgg({ children }: PropsWithChildren<{}>) {
	const enabled = useKonami(); // up up down down left right left right b a
	if (!enabled) return <>{children}</>;
	return <World />;
}

interface GameState {
	objects: WorldObject[];
	keys: Dictionary<boolean | undefined>;
	create: CallbackFor<[NewWorldObject]>;
	destroy: CallbackFor<[number]>;
	gameOver: Callback;
}

type WorldObjectType = 'bullet' | 'astroid' | 'player' | 'gameOver' | 'life' | 'explosion' | 'life-fade' | 'tutorial';
type Point = { x: number; y: number };
type Velocity = { speed: number; direction: number };
type Position = Point &
	Velocity & {
		previous: Point;
	};

interface ObjectMask {
	width: number;
	height: number;
	shape: 'round';
}
interface WorldObject {
	id: number;
	type: WorldObjectType;
	preStep?: CallbackFor<[number, GameState]>;
	step?: CallbackFor<[number, GameState]>;
	postStep?: CallbackFor<[number, GameState]>;
	render: React.FunctionComponent<{ id: number }>;
	mask: ObjectMask;
	position: Position;
	cooldowns?: Cooldown[];
}
type NewWorldObject = Omit<WorldObject, 'id'>;

interface Cooldown {
	value: number;
	duration: number;
	speed: number;
	percent: number;
}

const settings = {
	nextId: 0,
	worldSpeed: 30,
	renderSpeed: 30,
	pi: 3.14159265359,
	player: {
		startingLives: 4,
		startingDirection: 270,
		maxLives: 5,
		bulletOriginDistance: 20 / 2 + 8,
		movement: {
			minSpeed: 90,
			maxSpeed: 250,
			acceleration: 200,
			turningSpeed: 90,
		},
		weaponCooldown: {
			speed: 1,
			duration: 0.25,
		},
		damageCooldown: {
			speed: 1,
			duration: 1,
		},
	},
	astroid: {
		startSize: 100,
		startingCount: 10,
		smallestSize: 100 / 4,
		decreaseFactor: 2,
		speed: 100,
	},
	bullet: {
		speed: 600,
	},
	explosion: {
		speed: 1,
		duration: 0.5,
	},
	lives: {
		maxCount: 2,
		spawnChance: 0.1,
		duration: 5,
		size: 30,
	},
};

function World() {
	const { create, objects } = useWorldObjects();

	useMount(() => {
		create(
			createShip({
				x: window.innerWidth / 2,
				y: window.innerHeight / 2,
			})
		);
		create(
			createTutorial({
				x: window.innerWidth / 2,
				y: window.innerHeight / 2,
				key: 'A',
			})
		);

		for (let i = 0; i < settings.astroid.startingCount; i++) {
			const xOffset = (Math.random() * (window.innerWidth / 3) + window.innerWidth / 3) * (Math.random() > 0.5 ? 1 : -1);
			const yOffset = (Math.random() * (window.innerHeight / 3) + window.innerHeight / 3) * (Math.random() > 0.5 ? 1 : -1);

			create(
				createAstroid({
					x: window.innerWidth / 2 + xOffset,
					y: window.innerHeight / 2 + yOffset,
				})
			);
		}
	});

	return (
		<div
			className='position-fixed top-0 bottom-0 start-0 end-0'
			style={
				{
					zIndex: 1000,
					background: 'radial-gradient(circle at bottom, navy 0, black 100%)',
					'--meteor': 'rgba(255, 165, 0)',
					'--life': '#f24236',
				} as any
			}
		>
			<Stars key='stars' />
			{objects
				.filter((x) => x.render)
				.map((x) => (
					<x.render key={x.id} id={x.id} />
				))}
		</div>
	);
}

function useWorldObjects() {
	const keys = useKeyboard();
	const backingObjects = useRef<WorldObject[]>([]);
	const [objects, setObjects] = useState<WorldObject[]>([]);

	const create = useCallback((newObject: Omit<WorldObject, 'id'>) => {
		backingObjects.current = backingObjects.current.concat({
			id: settings.nextId,
			...newObject,
		});
		settings.nextId += 1;
	}, []);

	const gameOver = useCallback(() => {
		backingObjects.current = [];
		create(createGameOver(false));
	}, [create]);

	const destroy = useCallback(
		(id: number) => {
			backingObjects.current = backingObjects.current.filter((x) => x.id !== id);

			if (!backingObjects.current.find((o) => o.type === 'astroid') && !backingObjects.current.find((o) => o.type === 'gameOver')) {
				create(createGameOver(true));
			}
		},
		[create]
	);

	useEffect(() => {
		const render = setInterval(() => {
			setObjects(backingObjects.current.map((x) => x));
		}, 1000 / settings.renderSpeed);

		const update = setInterval(() => {
			const gameState = {
				keys: keys.current,
				objects: backingObjects?.current,
				create,
				destroy,
				gameOver,
			};

			backingObjects.current.forEach((worldObject) => {
				worldObject.preStep?.(worldObject.id, gameState);
			});
			backingObjects.current.forEach((worldObject) => {
				applyMovement(worldObject.position);
				worldObject.cooldowns?.forEach(applyCooldown);
			});
			backingObjects.current.forEach((worldObject) => {
				worldObject.step?.(worldObject.id, gameState);
			});
			backingObjects.current.forEach((worldObject) => {
				worldObject.postStep?.(worldObject.id, gameState);
			});
		}, 1000 / settings.worldSpeed);

		return () => {
			clearInterval(render);
			clearInterval(update);
			backingObjects.current = [];
		};
	}, [create, destroy, gameOver, keys]);

	return {
		objects,
		create,
		destroy,
	};
}

function Stars() {
	return (
		<div className='space'>
			<div className='stars1'></div>
			<div className='stars2'></div>
			<div className='stars3'></div>
		</div>
	);
}

function useKeyboard() {
	const [keys, setKeys] = useState<Dictionary<boolean | undefined>>({});
	const keysRef = useRef<Dictionary<boolean | undefined>>(keys);

	useEffect(() => {
		keysRef.current = keys;
	}, [keys]);

	useMount(() => {
		const downHandler = (e: KeyboardEvent) => {
			if (!e.repeat) {
				setKeys((prev) => {
					console.log('Handling Down Event', prev);
					return {
						...prev,
						[e.code]: true,
					};
				});
			}
		};

		const upHandler = (e: KeyboardEvent) => {
			setKeys((prev) => {
				console.log('Handling Up Event', prev);
				return {
					...prev,
					[e.code]: prev[e.code] === true ? false : undefined,
				};
			});
		};

		setTimeout(() => {
			// Place in a timeout to avoid catching the "A" press of Konami
			window.addEventListener('keydown', downHandler);
			window.addEventListener('keyup', upHandler);
		});

		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	});

	return keysRef;
}

function distance(point1: Point, point2: Point) {
	return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
}

function collision(objectA: Pick<WorldObject, 'mask' | 'position'>, objectB: Pick<WorldObject, 'mask' | 'position'>) {
	if (objectA.mask.shape === 'round' && objectB.mask.shape === 'round') {
		return (
			distance(objectA.position, objectB.position) <
			distance(
				{ x: 0, y: 0 },
				{
					x: objectA.mask.width / 2 + objectB.mask.width / 2,
					y: objectA.mask.height / 2 + objectB.mask.height / 2,
				}
			)
		);
	} else {
		console.warn('Collision Variant Not Implemented');
	}
}

function toRadians(degrees: number) {
	return (degrees * settings.pi) / 180;
}

function cosine(degrees: number) {
	return Math.cos(toRadians(degrees));
}

function sine(degrees: number) {
	return Math.sin(toRadians(degrees));
}

function applyMovement(position: Position) {
	if (position.direction >= 360) {
		position.direction -= 360;
	}

	if (position.direction < 0) {
		position.direction += 360;
	}

	position.previous.x = position.x;
	position.previous.y = position.y;
	position.x += cosine(position.direction) * (position.speed / settings.worldSpeed);
	position.y += sine(position.direction) * (position.speed / settings.worldSpeed);
}

function checkWorldBorder(position: Point, onWrap?: Callback) {
	let wrapped = false;

	if (position.x < 0) {
		position.x += window.innerWidth;
		wrapped = true;
	}
	if (position.x > window.innerWidth) {
		position.x -= window.innerWidth;
		wrapped = true;
	}
	if (position.y < 0) {
		position.y += window.innerHeight;
		wrapped = true;
	}
	if (position.y > window.innerHeight) {
		position.y -= window.innerHeight;
		wrapped = true;
	}

	if (wrapped) {
		onWrap?.();
	}
}

function cooldownAction(cooldown: Cooldown, action: Callback<boolean>) {
	if (cooldown.value <= 0) {
		if (action()) {
			cooldown.value = cooldown.duration;
			cooldown.percent = 1;
		}
	}
}

function applyCooldown(cooldown: Cooldown) {
	if (cooldown.value > 0) {
		cooldown.value = Math.max(0, cooldown.value - cooldown.speed / settings.worldSpeed);
		cooldown.percent = cooldown.value / cooldown.duration;
	}
}

function createShip({ x: xIn, y: yIn }: { x: number; y: number }): NewWorldObject {
	let lives = settings.player.startingLives;
	let weaponCooldown: Cooldown = {
		duration: settings.player.weaponCooldown.duration,
		speed: settings.player.weaponCooldown.speed,
		value: 0,
		percent: 0,
	};
	let damageCooldown: Cooldown = {
		duration: settings.player.damageCooldown.duration,
		speed: settings.player.damageCooldown.speed,
		value: 0,
		percent: 0,
	};

	const mask: ObjectMask = {
		height: 16,
		width: 16,
		shape: 'round',
	};

	let position: Position = {
		x: xIn,
		y: yIn,
		speed: settings.player.movement.minSpeed,
		direction: settings.player.startingDirection,
		previous: {
			x: 0,
			y: 0,
		},
	};

	const preStep = (id: number, state: GameState) => {
		if (state.keys.KeyA || state.keys.ArrowLeft) {
			position.direction -= settings.player.movement.turningSpeed / settings.worldSpeed;
		}
		if (state.keys.KeyD || state.keys.ArrowRight) {
			position.direction += settings.player.movement.turningSpeed / settings.worldSpeed;
		}

		if (state.keys.KeyW || state.keys.ArrowUp) {
			position.speed = Math.min(
				settings.player.movement.maxSpeed,
				position.speed + settings.player.movement.acceleration / settings.worldSpeed
			);
		}

		if (state.keys.KeyS || state.keys.ArrowDown) {
			position.speed = Math.max(
				settings.player.movement.minSpeed,
				position.speed - settings.player.movement.acceleration / settings.worldSpeed
			);
		}
	};

	const step = (id: number, state: GameState) => {
		checkWorldBorder(position);

		if (state.keys.Space) {
			cooldownAction(weaponCooldown, () => {
				const horizontalTranslation = {
					x: cosine(position.direction) * settings.player.bulletOriginDistance,
					y: sine(position.direction) * settings.player.bulletOriginDistance,
				};

				const frontOfShip = {
					x: position.x + horizontalTranslation.x,
					y: position.y + horizontalTranslation.y,
				};
				state.create(
					createBullet({
						direction: position.direction,
						...frontOfShip,
					})
				);

				return true;
			});
		}
	};

	const postStep = (id: number, state: GameState) => {
		cooldownAction(damageCooldown, () => {
			if (state.objects.filter((o) => o.type === 'astroid').find((a) => collision(a, { mask, position }))) {
				lives -= 1;
				if (lives === 0) {
					state.gameOver();
				}
				return true;
			}
			return false;
		});

		state.objects
			.filter((o) => o.type === 'life' && collision(o, { mask, position }))
			.forEach((life) => {
				lives = Math.min(settings.player.maxLives, lives + 1);
				state.create(createLifeFade(life.position));
				state.destroy(life.id);
			});
	};

	const render = () => (
		<>
			<div
				className='position-absolute start-0 top-0 end-0 p-2 text-center'
				style={{
					color: 'var(--life)',
					zIndex: 1,
					background: 'transparent',
				}}
			>
				<div
					className='p-2'
					style={{
						border: '4px solid white',
						borderRadius: 15,
						display: 'inline-block',
					}}
				>
					{array(lives).map((_a, i) => (
						<span key={'life' + i} className='mx-2'>
							<FontAwesomeIcon
								style={{
									fontSize: 40,
									color: i >= settings.player.startingLives ? 'var(--bs-primary)' : undefined,
								}}
								icon={['fas', 'heart']}
							/>
						</span>
					))}
					{array(settings.player.maxLives - lives).map((_a, i) => (
						<span key={'life-empty' + i} className='mx-2'>
							<FontAwesomeIcon
								style={{
									fontSize: 40,
									color: i + lives >= settings.player.startingLives ? 'var(--bs-primary)' : undefined,
								}}
								icon={['far', 'heart']}
							/>
						</span>
					))}
				</div>
			</div>
			<div
				style={{
					color: `rgb(255, ${255 * (1 - damageCooldown.percent)}, ${255 * (1 - damageCooldown.percent)})`,
					position: 'absolute',
					transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%)) rotate(${position.direction}deg)`,
				}}
			>
				<FontAwesomeIcon icon={['fas', 'shuttle-space']} />
			</div>
		</>
	);

	return {
		preStep,
		step,
		postStep,
		render,
		mask,
		type: 'player' as WorldObjectType,
		position,
		cooldowns: [weaponCooldown, damageCooldown],
	};
}

function createBullet({ x: xIn, y: yIn, direction: directionIn }: { x: number; y: number; direction: number }): NewWorldObject {
	let position: Position = {
		x: xIn,
		y: yIn,
		previous: {
			x: 0,
			y: 0,
		},
		direction: directionIn,
		speed: settings.bullet.speed,
	};

	const mask: ObjectMask = {
		width: 6,
		height: 6,
		shape: 'round',
	};

	const step = (id: number, state: GameState) => {
		checkWorldBorder(position, () => state.destroy(id));
	};

	const render = () => (
		<div
			style={{
				color: 'white',
				position: 'absolute',
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%))`,
				fontSize: '6px',
			}}
		>
			<FontAwesomeIcon icon={['fas', 'circle']} />
		</div>
	);

	return {
		step,
		render,
		mask,
		type: 'bullet' as WorldObjectType,
		position,
	};
}

function createAstroid({
	x: xIn,
	y: yIn,
	direction: directionIn,
	size: sizeIn,
}: {
	x: number;
	y: number;
	direction?: number;
	size?: number;
}): NewWorldObject {
	const size = typeof sizeIn === 'undefined' ? settings.astroid.startSize : sizeIn;

	let position: Position = {
		x: xIn,
		y: yIn,
		previous: {
			x: 0,
			y: 0,
		},
		speed: settings.astroid.speed,
		direction: typeof directionIn === 'undefined' ? Math.random() * 360 : directionIn,
	};

	const mask: ObjectMask = {
		width: size / 2,
		height: size / 2,
		shape: 'round',
	};

	const step = (id: number, state: GameState) => {
		checkWorldBorder(position);
	};

	const postStep = (id: number, state: GameState) => {
		const bullet = state.objects.filter((o) => o.type === 'bullet').find((b) => collision(b, { mask, position }));
		if (bullet) {
			state.destroy(bullet.id);
			if (size > settings.astroid.smallestSize) {
				state.create(
					createAstroid({
						x: position.x,
						y: position.y,
						direction: position.direction + 45,
						size: size / settings.astroid.decreaseFactor,
					})
				);
				state.create(
					createAstroid({
						x: position.x,
						y: position.y,
						direction: position.direction - 45,
						size: size / settings.astroid.decreaseFactor,
					})
				);
			}

			if (
				Math.random() <= settings.lives.spawnChance &&
				state.objects.filter((o) => o.type === 'life').length < settings.lives.maxCount
			) {
				state.create(
					createLife({
						x: position.x,
						y: position.y,
						direction: position.direction,
					})
				);
			}

			state.create(
				createExplosion({
					x: position.x,
					y: position.y,
					size: size,
				})
			);

			state.destroy(id);
		}
	};

	const render = () => (
		<>
			<div
				style={{
					color: 'var(--meteor)',
					position: 'absolute',
					transformOrigin: `38% 56%`,
					transform: `translate(calc(${position.x}px + -38%), calc(${position.y}px + -56%)) rotate(${
						position.direction - 135
					}deg)`,
					fontSize: `${size}px`,
				}}
			>
				<FontAwesomeIcon icon={['fad', 'meteor']} />
			</div>
		</>
	);

	return {
		step,
		postStep,
		render,
		mask,
		type: 'astroid' as WorldObjectType,
		position,
	};
}

function createGameOver(won: boolean): NewWorldObject {
	let position: Position = {
		x: window.innerWidth / 2,
		y: window.innerHeight / 2,
		previous: {
			x: 0,
			y: 0,
		},
		direction: 0,
		speed: 0,
	};

	let mask: ObjectMask = {
		width: 0,
		height: 0,
		shape: 'round',
	};

	const render = () => (
		<div
			className='ff-title'
			style={{
				color: 'white',
				position: 'absolute',
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%))`,
				fontSize: '50px',
			}}
		>
			{won ? <>You Won!!</> : <>Game Over</>}
		</div>
	);

	return {
		position,
		mask,
		render,
		type: 'gameOver',
	};
}

function createLife({ x: xIn, y: yIn, direction: directionIn }: { x: number; y: number; direction: number }): NewWorldObject {
	const size = 30;

	const life: Cooldown = {
		duration: settings.lives.duration,
		value: settings.lives.duration,
		percent: 1,
		speed: 1,
	};

	const mask: ObjectMask = {
		height: size,
		width: size,
		shape: 'round',
	};

	let position: Position = {
		x: xIn,
		y: yIn,
		speed: settings.astroid.speed / 3,
		direction: directionIn,
		previous: {
			x: 0,
			y: 0,
		},
	};

	const step = (id: number, state: GameState) => {
		checkWorldBorder(position);

		cooldownAction(life, () => {
			state.destroy(id);
			return true;
		});
	};

	const render = () => (
		<div
			style={{
				color: `var(--life)`,
				position: 'absolute',
				opacity: 0.2 + life.percent,
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%))`,
			}}
		>
			<FontAwesomeIcon style={{ fontSize: size }} icon={['fas', 'heart']} />
		</div>
	);

	return {
		mask,
		position,
		render,
		type: 'life',
		step,
		cooldowns: [life],
	};
}

function createExplosion({ x: xIn, y: yIn, size }: { x: number; y: number; size: number }): NewWorldObject {
	const life: Cooldown = {
		duration: settings.explosion.duration,
		value: settings.explosion.duration,
		percent: 1,
		speed: settings.explosion.speed,
	};

	const mask: ObjectMask = {
		height: 30,
		width: 30,
		shape: 'round',
	};

	let position: Position = {
		x: xIn,
		y: yIn,
		speed: 0,
		direction: Math.random() & 360,
		previous: {
			x: 0,
			y: 0,
		},
	};

	const step = (id: number, state: GameState) => {
		cooldownAction(life, () => {
			state.destroy(id);
			return true;
		});
	};

	const render = () => (
		<div
			style={{
				color: `rgba(255, ${255 * life.percent}, ${255 * life.percent}, ${life.percent + 0.2})`,
				position: 'absolute',
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%)) rotate(${position.direction}deg)`,
			}}
		>
			<FontAwesomeIcon style={{ fontSize: 30 * (1 - life.percent) + size }} icon={['fad', 'burst']} />
		</div>
	);

	return {
		mask,
		position,
		render,
		type: 'explosion',
		step,
		cooldowns: [life],
	};
}

function createLifeFade({ x: xIn, y: yIn }: { x: number; y: number }): NewWorldObject {
	const life: Cooldown = {
		duration: settings.explosion.duration,
		value: settings.explosion.duration,
		percent: 1,
		speed: settings.explosion.speed,
	};

	const mask: ObjectMask = {
		height: settings.lives.size,
		width: settings.lives.size,
		shape: 'round',
	};

	let position: Position = {
		x: xIn,
		y: yIn,
		speed: 0,
		direction: Math.random() & 360,
		previous: {
			x: 0,
			y: 0,
		},
	};

	const step = (id: number, state: GameState) => {
		cooldownAction(life, () => {
			state.destroy(id);
			return true;
		});
	};

	const render = () => (
		<div
			style={{
				color: `var(--life)`,
				position: 'absolute',
				opacity: life.percent,
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%))`,
			}}
		>
			<FontAwesomeIcon style={{ fontSize: settings.lives.size * (2 - life.percent) }} icon={['far', 'heart']} />
		</div>
	);

	return {
		mask,
		position,
		render,
		type: 'life-fade',
		step,
		cooldowns: [life],
	};
}

function createTutorial({ x: xIn, y: yIn, key }: { x: number; y: number; key: string }): NewWorldObject {
	const life: Cooldown = {
		duration: 3,
		value: 3,
		percent: 1,
		speed: 1,
	};

	const mask: ObjectMask = {
		height: 0,
		width: 0,
		shape: 'round',
	};

	let position: Position = {
		x: xIn,
		y: yIn,
		speed: 0,
		direction: 0,
		previous: {
			x: 0,
			y: 0,
		},
	};

	const keys = ['A', 'D', 'Space', 'W', 'S'];
	const step = (id: number, state: GameState) => {
		cooldownAction(life, () => {
			state.destroy(id);
			console.log(state.keys);
			const next =
				keys.indexOf(key) + (typeof state.keys['Key' + key] !== 'undefined' || typeof state.keys[key] !== 'undefined' ? 1 : 0);
			if (next < keys.length) {
				const player = state.objects.find((o) => o.type === 'player');
				if (player) {
					state.create(
						createTutorial({
							x: player.position.x,
							y: player.position.y,
							key: keys[next],
						})
					);
				}
			}
			return true;
		});
	};

	const render = () => (
		<div
			style={{
				position: 'absolute',
				background: 'rgba(255,255,255,0.2)',
				opacity: 0.2 + life.percent,
				color: `white`,
				fontSize: 50,
				border: '4px solid white',
				minWidth: 80,
				minHeight: 80,
				padding: '0.25rem',
				borderRadius: 10,
				transform: `translate(calc(${position.x}px + -50%), calc(${position.y}px + -50%))`,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{key}
		</div>
	);

	return {
		mask,
		position,
		render,
		type: 'life-fade',
		step,
		cooldowns: [life],
	};
}
