import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { useResourceContext } from './resource-context';

export function useCombinedResources() {
	const { sharedResources, departmentResources } = useResourceContext();
	const allResources = useCallback(async () => {
		const shared = await sharedResources();
		const department = await departmentResources();
		if (!shared || !department) {
			return null;
		}
		return department.concat(shared.filter((resource) => !department.find((x) => x.id === resource.id)));
	}, [departmentResources, sharedResources]);

	const [resources, setResources] = useAsyncState(allResources);

	const refresh = useCallback(() => {
		return allResources().then(setResources);
	}, [allResources, setResources]);

	return {
		resources,
		refresh,
	};
}
