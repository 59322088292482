import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const reorderEndpoint = new ApiCall<EndpointResponse>().post('reorder', {
	route: endpoints.reorderCategories,
	params: {
		order: ParameterDefinition.StringArray,
	},
});

export default reorderEndpoint;
