import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { useResourceContext } from './resource-context';

export function usePosts() {
	const { posts: getPosts, markPostsDirty: markDirty } = useResourceContext();
	const [posts, setPosts] = useAsyncState(getPosts);

	const refresh = useCallback(() => {
		markDirty();
		return getPosts().then(setPosts);
	}, [getPosts, markDirty, setPosts]);

	return {
		posts,
		refresh,
	};
}
