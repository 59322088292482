import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { PostLinks } from '../../../types/post';

export type EndpointResponse = { success: boolean };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', {
	route: endpoints.savePost,
	params: {
		id: OptionalParameterDefinition.String,
		title: ParameterDefinition.String,
		image: OptionalParameterDefinition.String,
		summary: ParameterDefinition.String,
		text: ParameterDefinition.String,
		links: {
			type: [] as PostLinks,
		},
	},
});

export default saveEndpoint;
