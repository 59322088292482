import { Loading } from 'src/components/loading';

export function LoadingOverlay() {
	return (
		<div
			className='position-fixed top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center'
			style={{
				zIndex: 999,
				background: 'rgba(19, 41, 61, 0.6)',
				backdropFilter: 'blur(3px)',
			}}
		>
			<div className='text-white osm'>
				<Loading text='Refreshing' />
			</div>
		</div>
	);
}
