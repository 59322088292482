import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const removeEndpoint = new ApiCall<EndpointResponse>().post('remove', {
	route: endpoints.deleteResourceType,
	params: {
		id: ParameterDefinition.String,
	},
});

export default removeEndpoint;
