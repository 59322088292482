import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', {
	route: endpoints.saveResource,
	params: {
		id: OptionalParameterDefinition.String,
		categoryId: OptionalParameterDefinition.String,
		category: OptionalParameterDefinition.String,
		title: ParameterDefinition.String,
		description: ParameterDefinition.String,
		isShared: ParameterDefinition.Boolean,
		isPinned: ParameterDefinition.Boolean,
		isOnboarding: ParameterDefinition.Boolean,
		resourceUrl: ParameterDefinition.String,
		previewImage: OptionalParameterDefinition.String,
		resourceType: ParameterDefinition.String,
	},
});

export default saveEndpoint;
