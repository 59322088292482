import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { Post } from '../../../types/post';

export type EndpointResponse = { posts: Post[] };
export const findEndpoint = new ApiCall<EndpointResponse>().get('find', {
	route: endpoints.getPosts,
});

export default findEndpoint;
