import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { Category } from 'src/types/category';
import { useResourceContext } from './resource-context';

export function useDepartmentResources() {
	const { departmentResources, markResourcesDirty: markDirty } = useResourceContext();
	const [resources, setResources] = useAsyncState(departmentResources);

	const updateResource = useCallback(
		(category: Category) => {
			setResources(
				(previous) =>
					previous?.map((p) => {
						if (p.category.id === category.id) {
							p.category = category;
						}
						return p;
					})
			);
			markDirty();
		},
		[markDirty, setResources]
	);

	const refresh = useCallback(() => {
		markDirty();
		return departmentResources().then(setResources);
	}, [departmentResources, markDirty, setResources]);

	return {
		resources,
		updateResource,
		refresh,
	};
}
