import { useLoadingOverlay } from '@lcms/react-loading-overlay';
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { api } from 'src/api';
import { Post } from 'src/types/post';
import { Resource } from 'src/types/resource';
import { useRefreshingLoad } from '../../hooks/use-refreshing-load';

interface ResourceContextType {
	departmentResources: () => Promise<Resource[] | null>;
	sharedResources: () => Promise<Resource[] | null>;
	posts: () => Promise<Post[] | null>;
	markResourcesDirty: () => void;
	markPostsDirty: () => void;
}

const resourceContext = React.createContext<ResourceContextType>({
	departmentResources: () => {
		throw new Error('No Provider');
	},
	sharedResources: () => {
		throw new Error('No Provider');
	},
	posts: () => {
		throw new Error('No Provider');
	},
	markResourcesDirty: () => {
		throw new Error('No Provider');
	},
	markPostsDirty: () => {
		throw new Error('No Provider');
	},
});

export function useResourceContext() {
	return useContext(resourceContext);
}

export function ResourceProvider({ children }: PropsWithChildren<{}>) {
	const { load } = useLoadingOverlay();
	const loadDepartmentResources = useCallback(() => {
		return load(() => api.resources.find({}).then((result) => result?.resources || null));
	}, [load]);
	const loadSharedResources = useCallback(() => {
		return load(() => api.resources.find({ shared: true }).then((result) => result?.resources || null));
	}, [load]);
	const loadPosts = useCallback(() => {
		return load(() => api.posts.find().then((result) => result?.posts || null));
	}, [load]);

	const { getPromise: departmentResources, markDirty: markDepartmentResourcesDirty } = useRefreshingLoad(loadDepartmentResources);
	const { getPromise: sharedResources, markDirty: markSharedResourcesDirty } = useRefreshingLoad(loadSharedResources);
	const { getPromise: posts, markDirty: markPostsDirty } = useRefreshingLoad(loadPosts);

	const markResourcesDirty = useCallback(() => {
		markDepartmentResourcesDirty();
		markSharedResourcesDirty();
	}, [markDepartmentResourcesDirty, markSharedResourcesDirty]);

	const context: ResourceContextType = useMemo(() => {
		return {
			departmentResources,
			sharedResources,
			markResourcesDirty,
			markPostsDirty,
			posts,
		};
	}, [departmentResources, markResourcesDirty, markPostsDirty, posts, sharedResources]);

	return <resourceContext.Provider value={context}>{children}</resourceContext.Provider>;
}
