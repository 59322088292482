import { FetchApi } from '@lcms/api-definition';
import { notifyUnauthorized } from '@lcms/react-identity';
import { ApiKey } from './api-key';
import { authenticationRouter } from './endpoints/authentication';
import { resourcesRouter } from './endpoints/resources';
import { resourceTypesRouter } from './endpoints/resource-types';
import { categoriesRouter } from './endpoints/categories';
import { postsRouter } from './endpoints/posts';
import { departmentsRouter } from './endpoints/departments';

export const api = FetchApi(ApiKey, notifyUnauthorized)
	.useRouter(authenticationRouter)
	.useRouter(resourcesRouter)
	.useRouter(resourceTypesRouter)
	.useRouter(categoriesRouter)
	.useRouter(postsRouter)
	.useRouter(departmentsRouter);
