import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { ResourceType } from '../../../types/resource-type';

export type EndpointResponse = { types: ResourceType[] };
export const findEndpoint = new ApiCall<EndpointResponse>().get('find', {
	route: endpoints.getResourceTypes,
});

export default findEndpoint;
