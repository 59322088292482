import { Api } from '@lcms/api-definition';
import { findEndpoint } from './find-endpoint';
import saveEndpoint from './save-endpoint';
import reorderEndpoint from './reorder-endpoint';
import removeEndpoint from './remove-endpoint';

export const resourceTypesRouter = Api.getRouter()
	.use(findEndpoint.inNamespace('resourcesTypes').toDefinition())
	.use(saveEndpoint.inNamespace('resourcesTypes').toDefinition())
	.use(reorderEndpoint.inNamespace('resourcesTypes').toDefinition())
	.use(removeEndpoint.inNamespace('resourcesTypes').toDefinition());
