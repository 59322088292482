import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faChevronLeft,
	faChevronRight,
	faFolders,
	faCheck,
	faTimes,
	faTrash,
	faH1,
	faH2,
	faH3,
	faH4,
	faH5,
	faH6,
	faBold,
	faUnderline,
	faItalic,
	faStrikethrough,
	faSubscript,
	faSuperscript,
	faHeart,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
	faChevronLeft,
	faChevronRight,
	faFolders,
	faCheck,
	faTimes,
	faTrash,
	faH1,
	faH2,
	faH3,
	faH4,
	faH5,
	faH6,
	faBold,
	faUnderline,
	faItalic,
	faStrikethrough,
	faSubscript,
	faSuperscript,
	faHeart
);
