import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = {
	image: string | null;
	title: string | null;
	description: string | null;
};
export const linkPreviewEndpoint = new ApiCall<EndpointResponse>().post('preview', {
	route: endpoints.getLinkPreview,
	params: {
		url: ParameterDefinition.String,
	},
});

export default linkPreviewEndpoint;
