import { useMemo } from 'react';

export function useYouTubeLink(url: string) {
	const youtube = useMemo(() => {
		const isYoutubeWatchLink = url.toLowerCase().startsWith('https://www.youtube.com/watch?');
		if (!isYoutubeWatchLink) return null;

		const uri = new URL(url);
		const video = uri.searchParams.get('v');
		if (!video) return null;

		return {
			videoId: video,
		};
	}, [url]);

	return youtube;
}
