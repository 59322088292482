import { useEffect } from 'react';
import { ReactComponent as LearningHubBusSvg } from './learning-hub-bus.svg';
import { Play } from './player';

export default function LearningHubBus() {
    useEffect(() => {
        Play();
    }, []);
    return <LearningHubBusSvg />;
}
