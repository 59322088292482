import React from 'react';

export type ToggleName = 'side-bar';
export type Toggle = Record<ToggleName, boolean>;

export interface ToggleContext {
	toggles: Toggle;
	setToggle: (name: ToggleName, value: boolean) => void;
	toggle: (name: ToggleName) => void;
}

export const toggleDefaults: Toggle = {
	'side-bar': true,
};

export const toggleContext = React.createContext<ToggleContext>({
	toggles: toggleDefaults,
	setToggle: () => {
		throw new Error('No Provider - ToggleContext');
	},
	toggle: () => {
		throw new Error('No Provider - ToggleContext');
	},
});
