import { routes } from '../routes';
import { AuthenticatedUser } from '@lcms/identity';
import { isAdmin } from 'src/features/authorization/is-admin';
import { User } from 'src/types/user';

export function itAdminRoute() {
	return (user: AuthenticatedUser<User> | null) => {
		if (!user || !isAdmin(user.user)) {
			return {
				redirect: routes.login,
				saveLocation: true,
			};
		}
		return null;
	};
}
