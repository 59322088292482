import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { success: boolean };
export const saveEndpoint = new ApiCall<EndpointResponse>().post('save', {
	route: endpoints.saveResourceType,
	params: {
		id: OptionalParameterDefinition.String,
		name: ParameterDefinition.String,
	},
});

export default saveEndpoint;
