import { Providers } from './providers';
import { Plugins } from './plugins';
import { EasterEgg } from './plugins/easter-egg';

export function App() {
	return (
		<EasterEgg>
			<Providers />
			<Plugins />
		</EasterEgg>
	);
}
