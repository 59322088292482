import { routes } from '../routes';
import { AuthenticatedUser } from '@lcms/identity';
import { User } from 'src/types/user';

export function userRoute() {
	return (authentication: AuthenticatedUser<User> | null) => {
		if (!authentication) {
			return {
				redirect: routes.login,
				saveLocation: true,
			};
		}
		if (!authentication.user.department?.id) {
			return {
				redirect: routes.invalidDepartment,
			};
		}
		return null;
	};
}
