import { Api } from '@lcms/api-definition';
import { loginEndpoint } from './login-endpoint';
import { logoutEndpoint } from './logout-endpoint';
import { meEndpoint } from './me-endpoint';
import { stayAliveEndpoint } from './stay-alive-endpoint';
import { profileEndpoint } from './profile-endpoint';

export const authenticationRouter = Api.getRouter()
	.use(loginEndpoint.inNamespace('authentication').toDefinition())
	.use(logoutEndpoint.inNamespace('authentication').toDefinition())
	.use(meEndpoint.inNamespace('authentication').toDefinition())
	.use(stayAliveEndpoint.inNamespace('session').toDefinition())
	.use(profileEndpoint.inNamespace('authentication').toDefinition());
