import { AuthenticationProvider } from '@lcms/react-identity';
import { PropsWithChildren } from 'react';
import { LoadingPage } from 'src/components/loading-page';
import { authenticationConfig } from './authentication-config';
import { AuthenticationPolling } from './authentication-polling';

export function Authentication({ children }: PropsWithChildren<{}>) {
	return (
		<AuthenticationProvider {...authenticationConfig} LoadingPage={<LoadingPage />}>
			<AuthenticationPolling />
			{children}
		</AuthenticationProvider>
	);
}
