import { notUndefined } from '@lcms/helpers';
import { Flex } from '@lcms/react-flex';
import LearningHubBus from './learning-hub-bus';

export function Loading({ text, className }: { text?: string; className?: string }) {
	return (
		<Flex justification='center' className={notUndefined(className) ? className : 'h-100'}>
			<div className='align-self-center text-center min-vw-50'>
				<div style={{ width: '50vw' }}>
					<LearningHubBus />
				</div>
				<span className='display-5'>{text || 'Loading...'}</span>
			</div>
		</Flex>
	);
}
