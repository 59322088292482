import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { useResourceTypesContext } from './resource-types-context';

export function useResourceTypes() {
	const { resourceTypes: getResourceTypes, markDirty } = useResourceTypesContext();
	const [resourceTypes, setResourceTypes] = useAsyncState(getResourceTypes);

	const refresh = useCallback(() => {
		markDirty();
		return getResourceTypes().then(setResourceTypes);
	}, [getResourceTypes, markDirty, setResourceTypes]);

	return {
		resourceTypes,
		refresh,
	};
}
