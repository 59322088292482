export const routes = {
	index: '/',
	shared: '/shared',
	admin: {
		home: '/admin',
		manage: {
			resourceTypes: '/admin/resource-types',
			departments: '/admin/departments',
		},
	},
	login: '/login',
	logout: '/logout',
	spotlight: '/spotlight',
	onboarding: '/onboarding',
	invalidDepartment: '/department-invalid',
	practice: '/practice',
};
