import { useLoadingOverlay } from '@lcms/react-loading-overlay';
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { api } from 'src/api';
import { useRefreshingLoad } from 'src/hooks/use-refreshing-load';
import { Department } from 'src/types/department';

interface DepartmentContextType {
	departments: () => Promise<Department[] | null>;
	markDirty: () => void;
}

const departmentsContext = React.createContext<DepartmentContextType>({
	departments: () => {
		throw new Error('No Provider');
	},
	markDirty: () => {
		throw new Error('No Provider');
	},
});

export function useDepartmentsContext() {
	return useContext(departmentsContext);
}

export function DepartmentsProvider({ children }: PropsWithChildren<{}>) {
	const { load } = useLoadingOverlay();
	const loadDepartments = useCallback(() => {
		return load(() => api.departments.find().then((result) => result?.departments || null));
	}, [load]);

	const { getPromise: departments, markDirty } = useRefreshingLoad(loadDepartments);

	const context: DepartmentContextType = useMemo(() => {
		return {
			departments,
			markDirty,
		};
	}, [departments, markDirty]);

	return <departmentsContext.Provider value={context}>{children}</departmentsContext.Provider>;
}
