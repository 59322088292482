import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faUser,
	faObjectsColumn,
	faLaptopMobile,
	faUserPlus,
	faRightFromBracket,
	faFolders,
	faChevronDown,
	faChevronUp,
	faBooks,
	faCirclePlus,
	faPencil,
	faImageLandscape,
	faChevronsDown,
	faChevronsUp,
	faShareNodes,
	faFile,
	faThumbTack,
	faCartFlatbedBoxes,
	faTrash,
	faArrowUpArrowDown,
	faGripDotsVertical,
	faLayerGroupPlus,
	faExclamationTriangle,
	faReply,
	faCircle,
	faPlay,
	faCogs,
	faDotCircle,
	faHouseBlank,
	faBars,
	faFolderOpen,
	faHouseChimney,
	faShuttleSpace,
	faHeart,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
	faUser,
	faObjectsColumn,
	faLaptopMobile,
	faUserPlus,
	faRightFromBracket,
	faFolders,
	faChevronDown,
	faChevronUp,
	faBooks,
	faCirclePlus,
	faPencil,
	faImageLandscape,
	faChevronsDown,
	faChevronsUp,
	faShareNodes,
	faFile,
	faThumbTack,
	faCartFlatbedBoxes,
	faTrash,
	faArrowUpArrowDown,
	faGripDotsVertical,
	faLayerGroupPlus,
	faExclamationTriangle,
	faReply,
	faCircle,
	faPlay,
	faCogs,
	faDotCircle,
	faHouseBlank,
	faBars,
	faFolderOpen,
	faHouseChimney,

	faShuttleSpace,
	faHeart
);
