import { api } from 'src/api';
import { notifyUnauthorized, useIsAuthenticated } from '@lcms/react-identity';
import { useEffect } from 'react';

export function AuthenticationPolling() {
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (!isAuthenticated) {
			return;
		}

		const interval = setInterval(() => {
			api.authentication.me().then((result) => {
				if (result?.status === 'anonymous') {
					notifyUnauthorized();
				}
			});
		}, 1000 * 15);

		return () => {
			clearInterval(interval);
		};
	}, [isAuthenticated]);

	return null;
}
