import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { Department } from '../../../types/department';

export type EndpointResponse = { departments: Department[] };
export const findEndpoint = new ApiCall<EndpointResponse>().get('find', {
	route: endpoints.getDepartments,
});

export default findEndpoint;
