import { ApiCall } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { Category } from '../../../types/category';

export type EndpointResponse = { categories: Category[] };
export const findEndpoint = new ApiCall<EndpointResponse>().post('find', {
	route: endpoints.getCategories,
	params: {},
});

export default findEndpoint;
