import { routes } from '../routes';
import { userRoute } from './user-route';
import { itAdminRoute } from './admin-route';

export const guards = {
	[routes.index]: userRoute(),
	[routes.shared]: userRoute(),
	[routes.onboarding]: userRoute(),
	[routes.spotlight]: userRoute(),
	[routes.admin.home]: itAdminRoute(),
};
