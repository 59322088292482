import { ApiCall, OptionalParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';
import { Resource } from '../../../types/resource';

export type EndpointResponse = { resources: Resource[] };
export const findEndpoint = new ApiCall<EndpointResponse>().post('find', {
	route: endpoints.getResources,
	params: {
		department: OptionalParameterDefinition.String,
		shared: OptionalParameterDefinition.Boolean,
	},
});

export default findEndpoint;
