import { LoadingOverlayProvider } from '@lcms/react-loading-overlay';
import { ToastProvider } from '@lcms/react-toasts';
import { ConfirmationProvider } from '@lcms/react-modal';
import { Routing } from 'src/routing/routing';
import { LoadingOverlay } from 'src/features/loading-overlay';
import { Authentication } from 'src/features/authentication';
import { ToggleProvider } from 'src/features/toggles';
import { ResourceProvider } from 'src/features/resources';
import { VideoPlayerProvider } from 'src/features/video-player';
import { DepartmentsProvider } from 'src/features/departments';
import { ResourceTypesProvider } from 'src/features/resource-types';

export function Providers() {
	return (
		<LoadingOverlayProvider overlay={<LoadingOverlay />}>
			<ToastProvider>
				<Authentication>
					<ToggleProvider>
						<ConfirmationProvider>
							<ResourceProvider>
								<DepartmentsProvider>
									<ResourceTypesProvider>
										<VideoPlayerProvider>
											<Routing />
										</VideoPlayerProvider>
									</ResourceTypesProvider>
								</DepartmentsProvider>
							</ResourceProvider>
						</ConfirmationProvider>
					</ToggleProvider>
				</Authentication>
			</ToastProvider>
		</LoadingOverlayProvider>
	);
}
