import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faDrawCircle,
	faShareNodes,
	faUsers,
	faGears,
	faUserPlus,
	faLaptopMobile,
	faSearch,
	faMessages,
	faChevronsDown,
	faChevronsUp,
	faTrashList,
	faSparkles,
	faMeteor,
	faBurst,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
	faDrawCircle,
	faShareNodes,
	faUsers,
	faGears,
	faUserPlus,
	faLaptopMobile,
	faSearch,
	faMessages,
	faChevronsDown,
	faChevronsUp,
	faTrashList,
	faSparkles,
	faMeteor,
	faBurst
);
