import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export type EndpointResponse = { data: string };
export const getPreviewEndpoint = new ApiCall<EndpointResponse>().post('getPreview', {
	route: endpoints.getResourcePreview,
	params: {
		url: ParameterDefinition.String,
	},
});

export default getPreviewEndpoint;
