import { useAsyncState } from '@lcms/react-helpers';
import { useCallback } from 'react';
import { useResourceContext } from './resource-context';

export function useSharedResources() {
	const { sharedResources } = useResourceContext();
	const [resources, setResources] = useAsyncState(sharedResources);

	const refresh = useCallback(() => {
		return sharedResources().then(setResources);
	}, [sharedResources, setResources]);

	return {
		resources,
		refresh,
	};
}
