import { useCallback, useContext, useMemo } from 'react';
import { toggleContext, ToggleName } from './toggle-context';

export function useToggle(name: ToggleName) {
	const { setToggle, toggle, toggles } = useContext(toggleContext);

	const setCurrent = useCallback(
		(value: boolean) => {
			setToggle(name, value);
		},
		[name, setToggle]
	);

	const toggleCurrent = useCallback(() => {
		toggle(name);
	}, [name, toggle]);

	const value = useMemo(() => toggles[name], [name, toggles]);

	return {
		set: setCurrent,
		toggle: toggleCurrent,
		value,
	};
}
