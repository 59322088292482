import { Callback, CallbackFor } from '@lcms/helpers';
import { InlineModal } from '@lcms/react-modal';
import React, { useContext, useMemo } from 'react';
import { PropsWithChildren, useCallback, useState } from 'react';
import { CloseButton } from 'src/components/buttons/close-button';

interface VideoPlayerContext {
	playVideo: CallbackFor<[{ videoId: string; title: string }]>;
	stopVideo: Callback;
}
const videoPlayerContext = React.createContext<VideoPlayerContext>({
	playVideo: () => {
		throw new Error('No Provider for Video Player');
	},
	stopVideo: () => {
		throw new Error('No Provider for Video Player');
	},
});

export function VideoPlayerProvider({ children }: PropsWithChildren<{}>) {
	const [video, setVideo] = useState<{ videoId: string; title: string }>();
	const playVideo = useCallback((video: { videoId: string; title: string }) => {
		setVideo(video);
	}, []);
	const stopVideo = useCallback(() => {
		setVideo(undefined);
	}, []);

	const context = useMemo(() => {
		return {
			playVideo,
			stopVideo,
		};
	}, [playVideo, stopVideo]);

	return (
		<videoPlayerContext.Provider value={context}>
			{children}

			<InlineModal
				Header={video?.title || ''}
				visible={!!video}
				wide
				dark
				onClickOutside={stopVideo}
				CloseButton={<CloseButton onClick={stopVideo} />}
			>
				{video ? (
					<iframe
						style={{
							width: '100%',
							aspectRatio: '16 / 9',
						}}
						src={`https://www.youtube.com/embed/${video.videoId}`}
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowFullScreen
					/>
				) : null}
			</InlineModal>
		</videoPlayerContext.Provider>
	);
}

export function useVideoPlayer() {
	return useContext(videoPlayerContext);
}
