import { Api } from '@lcms/api-definition';
import { findEndpoint } from './find-endpoint';
import { getPreviewEndpoint } from './get-preview-endpoint';
import { saveEndpoint } from './save-endpoint';
import { reorderEndpoint } from './reorder-endpoint';
import { removeEndpoint } from './remove-endpoint';
import { linkPreviewEndpoint } from './link-preview-endpoint';

export const resourcesRouter = Api.getRouter()
	.use(findEndpoint.inNamespace('resources').toDefinition())
	.use(getPreviewEndpoint.inNamespace('resources').toDefinition())
	.use(saveEndpoint.inNamespace('resources').toDefinition())
	.use(reorderEndpoint.inNamespace('resources').toDefinition())
	.use(removeEndpoint.inNamespace('resources').toDefinition())
	.use(linkPreviewEndpoint.inNamespace('links').toDefinition());
