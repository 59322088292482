import { Flex } from '@lcms/react-flex';
import { LoadingOverlay } from '../features/loading-overlay';

export function LoadingPage() {
	return (
		<div style={{ position: 'absolute', width: '100%', top: 50, bottom: 0 }}>
			<Flex justification='center' className='h-100'>
				<div className='align-self-center text-center'>
                    <LoadingOverlay />
					<span className='display-5 text-muted'>Loading</span>
				</div>
			</Flex>
		</div>
	);
}
