import { EnvironmentCommentPlugin } from './environment-comment-plugin';
import { MicrosoftClarityPlugin } from './microsoft-clarity-plugin';

export function Plugins() {
	return (
		<>
			<EnvironmentCommentPlugin />
			<MicrosoftClarityPlugin />
		</>
	);
}
