import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faTimes,
	faGripLines,
	faQuestionCircle,
	faPencil,
	faTimesCircle,
	faCircleNotch,
	faCircleCheck,
	faCirclePlus,
	faBorderOuter,
	faCircle,
	faFilterList,
} from '@fortawesome/pro-light-svg-icons';

library.add(
	faTimes,
	faGripLines,
	faQuestionCircle,
	faPencil,
	faTimesCircle,
	faCircleNotch,
	faCircleCheck,
	faCirclePlus,
	faBorderOuter,
	faCircle,
	faFilterList
);
