import { useLoadingOverlay } from '@lcms/react-loading-overlay';
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { api } from 'src/api';
import { useRefreshingLoad } from 'src/hooks/use-refreshing-load';
import { ResourceType } from 'src/types/resource-type';

interface ResourceTypesContextType {
	resourceTypes: () => Promise<ResourceType[] | null>;
	markDirty: () => void;
}

const resourceTypesContext = React.createContext<ResourceTypesContextType>({
	resourceTypes: () => {
		throw new Error('No Provider');
	},
	markDirty: () => {
		throw new Error('No Provider');
	},
});

export function useResourceTypesContext() {
	return useContext(resourceTypesContext);
}

export function ResourceTypesProvider({ children }: PropsWithChildren<{}>) {
	const { load } = useLoadingOverlay();
	const loadResourceTypes = useCallback(() => {
		return load(() => api.resourcesTypes.find().then((result) => result?.types || null));
	}, [load]);

	const { getPromise: resourceTypes, markDirty } = useRefreshingLoad(loadResourceTypes);

	const context: ResourceTypesContextType = useMemo(() => {
		return {
			resourceTypes,
			markDirty,
		};
	}, [resourceTypes, markDirty]);

	return <resourceTypesContext.Provider value={context}>{children}</resourceTypesContext.Provider>;
}
