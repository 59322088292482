import { MouseOverTooltip } from '@lcms/react-tooltips';
import { Flex } from '@lcms/react-flex';
import { ButtonProps } from './button-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CloseButton({ className, children, ...props }: ButtonProps) {
	return (
		<div>
			<MouseOverTooltip content='Close' placement='left'>
				<button type='button' {...props} className={`btn btn-close ${className || ''}`} aria-label='Close'>
					<Flex alignment='center'>
						<div>
							<FontAwesomeIcon icon={['far', 'times']} />
						</div>
						{children}
					</Flex>
				</button>
			</MouseOverTooltip>
		</div>
	);
}
